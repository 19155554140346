@font-face {
  font-family: 'Monument Grotesk';
  src: url('./../../public/fonts/monument-grotesk/MonumentGrotesk-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk';
  src: url('./../../public/fonts/monument-grotesk/MonumentGrotesk-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk';
  src: url('./../../public/fonts/monument-grotesk/MonumentGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
