@import './assets/styles/mixins';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

div,
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span {
  outline: none;
}

sup {
  vertical-align: super;
}

sub {
  vertical-align: sub;
}

strong,
b {
  font-weight: bold;
}

textarea {
  overflow: auto;
  resize: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
ol {
  list-style-type: none;
}

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img,
fieldset,
a img {
  border: none;
}

input,
button {
  margin: 0;
  padding: 0;
  border: 0;
  border: none;
  border-radius: 0;
}

input,
textarea,
select,
button {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
}

input {
  box-shadow: none;
  font-size: inherit;
}

input::-ms-clear {
  display: none;
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='search'],
input[type='number'],
textarea,
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='submit'],
button {
  background-color: transparent;
  cursor: pointer;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='submit'] {
  appearance: none;
}

input::-webkit-clear-button,
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

svg {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  overflow: visible;
}

button {
  background: 0 0;
}

ul li > a {
  display: inline-block;
  width: 100%;
}

img {
  display: block;
  max-width: 100%;
}

template {
  display: none !important;
}

html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  line-height: 1.375;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-drag: none;
}

:root {
  --layout-header-z-index: 10;
  --layout-content-z-index: 9;
  --layout-footer-z-index: 9;
  --layout-overlay-bg: rgba(var(--clr-text-main-rgb), 0.2);
  --layout-overlay-opacity: 0;
  --nav-dropdown-transition: opacity 0.16s;

  --map-controls-rspace-desktop: 56px;
  --map-controls-rspace-tablet: 36px;
  --map-controls-rspace-mTablet: 26px;
  --map-controls-rspace-lMobile: 16px;
}

body {
  width: 100%;
  font-family: $font-main;
  font-weight: 400;
  color: $white;
  -webkit-overflow-scrolling: touch;
  background-color: $black;
  // overflow-x: hidden !important;
  overflow-x: clip !important;

  &.content-fixed {
    position: fixed;
  }
}

.align-center {
  text-align: center;
}

blockquote {
  position: relative;
  background: #eeeeee80;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic;
  border-radius: 15px;

  &:before {
    position: absolute;
    content: '"';
    font-size: 34px;
  }

  &:after {
    position: absolute;
    content: '"';
    font-size: 34px;
    right: 15px;
    bottom: 0;
  }
}

.tippy-content {
  padding: 0 !important;
}

.tippy-box {
  background: var(--clr-bg-tooltip) !important;
  border-radius: 8px !important;
}

.tippy-arrow {
  color: var(--clr-bg-tooltip) !important;

  &:before {
    color: var(--clr-bg-tooltip) !important;
  }
}

.swiper-pagination {
  position: static !important;
  margin-top: 20px;

  @include maxWidth(450) {
    margin-top: 20px;
  }
}

.swiper-pagination-bullet {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: $white-80 !important;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: $blue !important;
}

.overflow {
  overflow: hidden !important;
}

.belatra-notification {
  padding: 20px !important;
  background: rgba(0, 65, 255, 0.31) !important;
  backdrop-filter: blur(50px) !important;
  border-radius: 20px !important;
  .Toastify__close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    color: #bbb9b6;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

body {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #7f7f7f;
  }
}

// .marquee {
//   gap: 25px;
// }

// .marquee-container {
//   gap: 25px;
//   padding: 50px 0 20px;
// }
